<template>
	<el-container class="friend-page">
		<el-aside width="260px" class="friend-list-box">
			<div class="friend-list-header">
				<el-input class="search-text" placeholder="搜索" v-model="searchText">
					<i class="el-icon-search el-input__icon" slot="prefix"> </i>
				</el-input>
				<el-button plain class="add-btn" icon="el-icon-plus" title="添加好友"
					@click="onShowAddFriend()"></el-button>
				<add-friend :dialogVisible="showAddFriend" @close="onCloseAddFriend"></add-friend>
			</div>
			<el-scrollbar class="friend-list-items">
				<div v-for="(friend,index) in $store.state.friendStore.friends" :key="index">
					<friend-item v-show="friend.nickName.startsWith(searchText)" :index="index"
						:active="friend === $store.state.friendStore.activeFriend" @chat="onSendMessage(friend)"
						@delete="onDelItem(friend,index)" @click.native="onActiveItem(friend,index)">
					</friend-item>
				</div>
			</el-scrollbar>
		</el-aside>
		<el-container class="friend-box">
			<div class="friend-header" v-show="userInfo.id">
				{{userInfo.nickName}}
			</div>
			<div v-show="userInfo.id">
				<div class="friend-detail">
					<head-image :size="200" :name="userInfo.nickName" :url="userInfo.headImage"
						@click.native="showFullImage()"></head-image>
					<div>
						<div class="info-item">
							<el-descriptions title="好友信息" class="description" :column="1">
								<el-descriptions-item label="用户名">{{ userInfo.userName }}
								</el-descriptions-item>
								<el-descriptions-item label="昵称">{{ userInfo.nickName }}
								</el-descriptions-item>
								<el-descriptions-item label="性别">{{ userInfo.sex==0?"男":"女" }}</el-descriptions-item>
								<el-descriptions-item label="签名">{{ userInfo.signature }}</el-descriptions-item>
							</el-descriptions>

						</div>
						<div class="frient-btn-group">
							<el-button v-show="isFriend" icon="el-icon-position" type="primary"
								@click="onSendMessage(userInfo)">发消息</el-button>
							<el-button v-show="!isFriend" icon="el-icon-plus" type="primary"
								@click="onAddFriend(userInfo)">加为好友</el-button>
							<el-button v-show="isFriend" icon="el-icon-delete" type="danger"
								@click="onDelItem(userInfo,activeIdx)">删除好友</el-button>
						</div>
					</div>
				</div>
				<el-divider content-position="center"></el-divider>

			</div>
		</el-container>
	</el-container>
</template>

<script>
	import FriendItem from "../components/friend/FriendItem.vue";
	import AddFriend from "../components/friend/AddFriend.vue";
	import HeadImage from "../components/common/HeadImage.vue";

	export default {
		name: "friend",
		components: {
			FriendItem,
			AddFriend,
			HeadImage

		},
		data() {
			return {
				searchText: "",
				showAddFriend: false,
				activeIdx: -1,
				userInfo: {}
			}
		},
		methods: {
			onShowAddFriend() {
				this.showAddFriend = true;
			},
			onCloseAddFriend() {
				this.showAddFriend = false;
			},
			onActiveItem(friend, idx) {
				this.$store.commit("activeFriend", idx);
				this.activeIdx = idx
				this.loadUserInfo(friend, idx);
			},
			onDelItem(friend, idx) {
				this.$confirm(`确认要解除与 '${friend.nickName}'的好友关系吗?`, '确认解除?', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http({
						url: `/friend/delete/${friend.id}`,
						method: 'delete'
					}).then((data) => {
						this.$message.success("删除好友成功");
						this.$store.commit("removeFriend", idx);
						this.$store.commit("removePrivateChat", friend.id);
					})
				})
			},
			onAddFriend(user) {
				this.$http({
					url: "/friend/add",
					method: "post",
					params: {
						friendId: user.id
					}
				}).then((data) => {
					this.$message.success("添加成功，对方已成为您的好友");
					let friend = {
						id: user.id,
						nickName: user.nickName,
						headImage: user.headImage,
						online: user.online
					}
					this.$store.commit("addFriend", friend);
				})
			},
			onSendMessage(user) {
				let chat = {
					type: 'PRIVATE',
					targetId: user.id,
					showName: user.nickName,
					headImage: user.headImageThumb,
				};
				this.$store.commit("openChat", chat);
				this.$store.commit("activeChat", 0);
				this.$router.push("/home/chat");
			},
			showFullImage() {
				if (this.userInfo.headImage) {
					this.$store.commit('showFullImageBox', this.userInfo.headImage);
				}
			},
			updateFriendInfo(friend, user, index) {
				// store的数据不能直接修改，深拷贝一份store的数据
				friend = JSON.parse(JSON.stringify(friend));
				friend.headImage = user.headImageThumb;
				friend.nickName = user.nickName;
				this.$http({
					url: "/friend/update",
					method: "put",
					data: friend
				}).then(() => {
					this.$store.commit("updateFriend", friend);
					this.$store.commit("updateChatFromFriend", user);
				})
			},
			loadUserInfo(friend, index) {
				this.$http({
					url: `/user/find/${friend.id}`,
					method: 'get'
				}).then((user) => {
					this.userInfo = user;
					// 如果发现好友的头像和昵称改了，进行更新
					if (user.headImageThumb != friend.headImage ||
						user.nickName != friend.nickName) {
						this.updateFriendInfo(friend, user, index)
					}
				})
			}
		},
		computed: {
			friendStore() {
				return this.$store.state.friendStore;
			},
			isFriend() {
				return this.friendStore.friends.find((f) => f.id == this.userInfo.id);
			}
		}
	}
</script>

<style lang="scss">
	.friend-page {
		.friend-list-box {
			display: flex;
			flex-direction: column;
			border: none;
			background: white;
			box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			margin-right: 16px;
			transition: all 0.3s ease;
			overflow: hidden;

			.friend-list-header {
				height: 66px;
				display: flex;
				align-items: center;
				padding: 5px 12px;
				background-color: #f5f7fa;
				position: relative;
				
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 5%;
					width: 90%;
					height: 1px;
					background: rgba(0, 0, 0, 0.05);
				}
				
				.el-input__inner {
					border-radius: 20px !important;
					transition: all 0.3s ease;
					border: 1px solid #e6e6e6;
					background-color: white;
					
					&:focus {
						box-shadow: 0 0 0 2px rgba(220, 223, 230, 0.3);
					}
				}
				
				.add-btn {
					padding: 10px !important;
					margin: 5px;
					font-size: 20px;
					color: #409EFF;
					border: 1px solid #DCDFE6;
					background-color: white;
					border-radius: 50%;
					transition: all 0.3s ease;
					
					&:hover {
						background-color: #ecf5ff;
						color: #66b1ff;
						border-color: #c6e2ff;
					}
					
					&:active {
						color: #3a8ee6;
					}
				}
			}

			.friend-list-items {
				flex: 1;
				margin: 5px;
				background: white;
				border-radius: 0 0 8px 8px;
				padding: 5px;
			}
		}

		.friend-box {
			display: flex;
			flex-direction: column;
			border: none;
			box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			overflow: hidden;
			transition: all 0.3s ease;
			flex: 1;
			background-color: white;

			.friend-header {
				padding: 3px;	
				height: 66px;
				line-height: 66px;
				font-size: 20px;
				font-weight: 600;
				text-align: center;
				background-color: #f5f7fa;
				color: #303133;
				border: none;
				position: relative;
				
				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 5%;
					width: 90%;
					height: 1px;
					background: rgba(0, 0, 0, 0.05);
				}
			}

			.friend-detail {
				display: flex;
				padding: 40px 60px 20px 60px;
				text-align: center;
				background-color: white;
				
				.el-avatar {
					border-radius: 8px;
					box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
					transition: all 0.3s ease;
					cursor: pointer;
					
					&:hover {
						box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
						transform: scale(1.02);
					}
				}
				
				.info-item {
					margin-left: 40px;
					background-color: #f9f9f9;
					border-radius: 8px;
					border: 1px solid #EBEEF5;
					transition: all 0.3s ease;
					position: relative;
					overflow: hidden;
					
					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 3px;
						background-color: #409EFF;
					}
					
					&:hover {
						box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
						transform: translateY(-2px);
					}
				}

				.description {
					padding: 25px 20px 15px 20px;
					
					.el-descriptions__title {
						font-size: 16px;
						color: #409EFF;
						font-weight: 600;
						margin-bottom: 15px;
					}
					
					.el-descriptions-item__label {
						color: #606266;
						font-weight: 500;
					}
					
					.el-descriptions-item__content {
						color: #303133;
						font-size: 14px;
					}
				}
			}

			.frient-btn-group {
				text-align: left !important;
				padding: 20px 40px;
				background-color: white;
				
				.el-button {
					border-radius: 8px;
					padding: 10px 20px;
					transition: all 0.3s ease;
					font-weight: 500;
					margin-right: 15px;
					
					&.el-button--primary {
						background-color: #409EFF;
						border: none;
						box-shadow: 0 2px 6px rgba(64, 158, 255, 0.2);
						
						&:hover {
							background-color: #66b1ff;
							box-shadow: 0 4px 12px rgba(64, 158, 255, 0.3);
							transform: translateY(-2px);
						}
						
						&:active {
							transform: translateY(-1px);
						}
						
						i {
							margin-right: 5px;
							transition: all 0.3s ease;
						}
					}
					
					&.el-button--danger {
						background-color: #F56C6C;
						border: none;
						box-shadow: 0 2px 6px rgba(245, 108, 108, 0.2);
						
						&:hover {
							background-color: #f78989;
							box-shadow: 0 4px 12px rgba(245, 108, 108, 0.3);
							transform: translateY(-2px);
						}
						
						&:active {
							transform: translateY(-1px);
						}
					}
				}
			}
		}
	}
	
	.el-divider {
		margin: 20px 0;
		
		.el-divider__text {
			background-color: white;
			color: #409EFF;
			font-weight: 500;
			padding: 0 15px;
		}
	}
</style>